.Container-Date-Match{
    display: flex;
    justify-content: center;
    width: 100px;
    border-radius: 7px 7px 0 0;
    font-size: 11px;
    background-color: #0267e2b8;
    color: #FFFFFF;
}

.Container-Detail-Match{
    display: flex;
    justify-content: center;
    border-radius: 7px;
    gap: 10px;
    align-items: center;
    background: #0266E2;
    color: #FFFFFF;
}